.notice {
  @apply fixed left-0 w-full bottom-0 z-50 text-sm;
  bottom: theme(spacing.4);

  &__content {
    @apply bg-white rounded-lg shadow-2xl;
  }

  &__title {
    max-height: 40vh;
    overflow-y: auto;
  }

  &__btns {
    .btn {
      @apply block mb-1 px-4 mr-0 text-lg font-semibold;

      &:last-child {
        @apply mb-0;
      }
    }
  }

  &__cookie-modal {
    @apply bg-white p-4 max-w-2xl text-sm;
    max-height: 80vh;
    overflow-y: auto;

    label {
      @apply mr-2 mt-1 inline-block;

      input {
        @apply pr-1 inline-block;
      }
    }

    &-close {
      @apply absolute top-0 right-0 mr-2 mt-2 z-50 fill-primary border-2 border-primary;

      & > * {
        pointer-events: none;
      }

      svg {
        @apply w-3 h-3;
      }
    }
  }
}
