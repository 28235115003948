@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC&display=swap');

body {
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: Montserrat, sans-serif, 'Noto Sans SC';
}

a {
  @apply text-primary;
}

@layer base {
  .headline {
    @apply text-primary font-semibold mb-4 block;
    font-size: calc(theme(fontSize.headline) - 16px);
    line-height: calc(64px - 16px);

    @screen lg {
      @apply text-headline;
      line-height: 64px;
    }

    &--secondary {
      @apply text-secondary;
    }

    &:first-child:last-child {
      @apply mb-0;
    }
  }

  h1,
  .h1 {
    @apply font-semibold mb-4;
    line-height: 48px;
    font-size: calc(theme(fontSize.h1) - 8px);

    @screen lg {
      @apply text-h1;
    }

    &:first-child:last-child {
      @apply mb-0;
    }
  }

  h2,
  .h2 {
    @apply font-semibold mb-4;
    line-height: 40px;
    font-size: calc(theme(fontSize.h2) - 4px);

    @screen lg {
      @apply text-h2;
    }

    &:first-child:last-child {
      @apply mb-0;
    }
  }

  h3,
  .h3 {
    @apply font-semibold mb-2;
    line-height: 30px;
    font-size: calc(theme(fontSize.h3) - 2px);

    @screen lg {
      @apply text-h3;
    }

    &:first-child:last-child {
      @apply mb-0;
    }
  }

  h4,
  .h4 {
    @apply font-semibold mb-2;
    line-height: 24px;
    font-size: calc(theme(fontSize.h4) - 2px);

    @screen lg {
      @apply text-h4;
    }
  }

  h5,
  .h5 {
    @apply font-semibold mb-2;
    line-height: 24px;
    font-size: calc(theme(fontSize.h5) - 2px);

    @screen lg {
      @apply text-h5;
    }

    &:first-child:last-child {
      @apply mb-0;
    }
  }

  .big {
    font-size: 20px;
  }

  .subheadline {
    @apply block uppercase font-semibold;
    font-size: 20px;

    &--small {
      @apply text-sm;
    }

    &--primary {
      @apply text-primary;
    }
  }

  .divider {
    &::after {
      @apply w-5 mx-auto block bg-secondary mb-4 mt-3;
      content: '';
      height: 4px;
    }

    &--align-left {
      &::after {
        @apply ml-0;
      }
    }

    &--vertical {
      &::after {
        @screen lg {
          @apply transform rotate-90 my-5;
        }
      }
    }
  }

  p {
    @apply mb-3;
  }
}
