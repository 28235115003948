#hs-eu-cookie-confirmation {
  @apply hidden;
}

.hubspot-form {
  .hs-richtext.hs-main-font-element h1,
  .hs-richtext.hs-main-font-element h2 {
    @extend .h2;
    @extend .divider;
    @apply text-center;
  }

  .hs-form {
    @apply my-2;
  }

  .hs-fieldtype-select .input {
    @extend .input-select;
    @apply w-full;

    select {
      @apply w-full border-none p-0;
    }
  }

  .inputs-list {
    @apply list-none;
    padding: 0 !important;

    li {
      @apply list-none;
    }
  }

  .hs-fieldtype-radio .input,
  .hs-fieldtype-checkbox .input {
    input {
      @apply inline-block align-middle;
    }

    span {
      @apply inline-block align-middle pl-1 text-base text-gray-dark;
    }
  }

  .hs-fieldtype-text .input {
    @extend .input-text;
    @apply p-0;

    input {
      padding: 0 !important;
      width: 100% !important;
    }
  }

  fieldset {
    @apply border border-gray-light;
    border-left: 2px solid #eee !important;
    border-right: 2px solid #eee !important;
    border-top: 2px solid #eee !important;
    max-width: 100% !important;

    &:first-child {
      /* border: 0 !important; */

      .hs-richtext {
        @apply text-lg;
      }
    }
  }

  [class*='form-columns'] {
    @screen sm {
      .hs-form-field {
        border-left: 2px solid #eee;

        &:first-child {
          border: none;
        }
      }
    }
  }

  .hs-form-field {
    @apply py-1 px-2;

    label {
      @apply text-sm text-primary block;
    }

    textarea {
      min-height: 200px;
    }
  }

  .hs-richtext {
    @apply my-2 max-w-screen-sm text-sm mx-auto pb-px px-2;
  }

  .hs-fieldtype-booleancheckbox {
    @apply my-2 max-w-screen-sm text-sm mx-auto border-0 p-0 px-2;

    input {
      @apply inline-block align-middle;
      margin-top: 4px;
    }

    span {
      @apply inline-block align-middle pl-1 text-base text-gray-dark;
    }

    ul {
      padding: 0 !important;

      li {
        @apply list-none;
      }
    }
  }

  .hs-recaptcha {
    @apply border-2 border-gray-light border-t-0 border-b-0 pb-3 px-2;

    .grecaptcha-badge {
      @apply mx-auto;
    }
  }

  .hs-submit {
    @apply border-2 border-gray-light border-t-0 pb-3 px-2;

    input {
      @apply max-w-screen-sm text-white mx-auto p-2 bg-primary block w-full my-0;
    }
  }

  .hs-error-msgs {
    padding: 0 !important;
    margin: 0 !important;

    li {
      @apply list-none;

      label {
        @apply text-warning;
      }
    }
  }
}
