%input-select {
  @apply relative inline-block max-w-full;

  select {
    @apply p-2 border-2 border-gray-light text-gray-dark pl-4 pr-5 appearance-none max-w-full w-full;
    min-width: 200px;
  }

  &::after {
    @apply w-3 h-3 absolute z-10 transform rotate-90 -translate-y-1/2 pointer-events-none bg-secondary;
    right: 24px;
    top: 50%;
    content: '';
    mask-image: url(../icons/chevron-right.svg);
  }
}

%input-checkbox,
%input-radio {
  @apply grid grid-flow-col justify-start gap-1 font-semibold;

  input {
    @apply appearance-none h-3 w-3 border-2 border-primary rounded overflow-hidden relative;
    transition: all .25s;

    &:checked {
      @apply bg-primary;
    }
  }
}

%input-checkbox {
  input {
    &::after {
      @apply h-3 w-3 bg-white block absolute;
      content: '';
      top: -2px;
      left: -2px;
    }

    &:checked {
      &::after {
        mask-image: url(../icons/done.svg);
      }
    }
  }
}

%input-text {
  @apply bg-white py-2 px-2 border-gray-light;

  @screen lg {
    @apply px-4;
  }

  input {
    @apply appearance-none text-gray-dark;
    min-width: 120px;
  }

  .icon svg {
    @apply fill-primary w-4 h-4 mr-1;
  }

  &--small-icon {
    .icon svg {
      @apply w-3 h-3 fill-secondary;
    }
  }
}

.input-select {
  @extend %input-select;
}

.input-radio {
  @extend %input-radio;
}

.input-checkbox {
  @extend %input-checkbox;
}

.input-text {
  @extend %input-text;
}
