.btn {
  @apply text-left text-gray-dark;

  &.active {
    @apply font-semibold;
  }

  &:hover {
    filter: brightness(90%);
  }

  &:focus {
    outline: solid 3px #ddd;
  }

  &--bigger {
    @apply font-semibold align-middle;
    min-height: 46px;
    line-height: 2;
  }

  &--icon {
    @apply inline-block;

    & > span,
    & > i {
      @apply inline-block align-middle;
    }

    .icon--play {
      svg {
        @apply w-3 h-3;
      }
    }
  }

  &--filter {
    @apply text-base p-0 px-1 rounded grid justify-start items-center grid-flow-col;

    .icon svg {
      @apply mr-1;
    }

    &-light {
      @apply bg-gray-light;

      svg {
        @apply fill-gray-dark;
      }
    }

    &-dark {
      @apply text-white bg-gray-dark;

      &:hover {
        @apply opacity-75;
      }

      svg {
        @apply fill-white;
      }
    }

    &-primary {
      @apply text-white bg-primary;

      &:hover {
        @apply opacity-75;
      }

      svg {
        @apply fill-white;
      }
    }
  }

  &--text-primary {
    @apply text-primary font-semibold;

    svg {
      @apply fill-secondary;
    }
  }

  &--text-secondary {
    @apply text-secondary font-semibold;

    svg {
      @apply fill-secondary;
    }
  }

  &--text-dark {
    @apply text-black font-semibold;

    svg {
      @apply fill-secondary;
    }
  }

  &--text-white {
    @apply text-white;

    svg {
      @apply fill-white;
    }
  }

  &--primary {
    @apply bg-primary text-white text-base py-1 px-2;

    &:focus {
      outline: solid 3px #c9efff;
    }

    svg {
      @apply fill-white;
    }
  }

  &--secondary {
    @apply bg-secondary text-white text-base py-1 px-2;

    &:focus {
      outline: solid 3px #ceffd5;
    }

    svg {
      @apply fill-white;
    }
  }

  &--dark {
    @apply bg-gray-dark text-white text-base py-1 px-2;

    &:hover {
      @apply opacity-75;
    }

    svg {
      @apply fill-white;
    }
  }

  &--dark2 {
    @apply text-white text-base py-1 px-2;
    background-color: #333;

    &:hover {
      @apply opacity-75;
    }

    svg {
      @apply fill-white;
    }
  }

  &--light {
    @apply bg-gray-light text-gray-dark text-base py-1 px-2;

    svg {
      @apply fill-gray-dark;
    }
  }

  &[disabled] {
    @apply bg-gray-light text-gray-dark opacity-50;
  }

  &--whatsapp {
    color: #25d366;

    svg {
      fill: #25d366;
    }
  }
}
